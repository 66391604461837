import React, { useEffect, useContext } from "react";
import ThemeContext from "../stores/ThemeContext";
import { positionFollower } from "../helpers/mouseFollow"

const CursorFollow = () => {
  let { themeOpt } = useContext(ThemeContext);
  //  console.log(themeOpt);

  useEffect(() => {
    const follower = document.querySelector("#point");
    if (follower) {
      const mouseMover = setInterval(positionUpdate, 15);
      window.addEventListener("mousemove", mouseMoveHandler);

      let currentMousePos = { x: -1, y: -1 };
      function mouseMoveHandler(event) {
        currentMousePos.x = event.pageX;
        currentMousePos.y = event.pageY;
      }

      function positionUpdate() {
        positionFollower(currentMousePos, follower)
      }

      return () => {
        clearInterval(mouseMover);
        window.removeEventListener("mousemove", mouseMoveHandler);
      };
    }
  }, []);

  return (
    <div id="point" className={themeOpt.cfState}></div>
  );
};
export default CursorFollow;
