export const positionFollower = (currentMousePos, follower) => {

  var x_cursor = currentMousePos.x;
  var y_cursor = currentMousePos.y;
  var rect = follower.getBoundingClientRect();
  var position = {
    top: rect.top + window.scrollY,
    left: rect.left + window.scrollX
  };
  var x_box = position.left + follower.offsetWidth / 2;
  var y_box = position.top + follower.offsetHeight / 2;

  follower.style.left = x_box + 0.1 * (x_cursor - x_box) + "px";
  follower.style.top = y_box + 0.1 * (y_cursor - y_box) + "px";

}