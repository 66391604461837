import React, { useEffect, useReducer } from "react";
import CursorFollow from "../components/CursorFollow";
import ThemeContext from "../stores/ThemeContext";
import ThemeReducer from "../stores/ThemeReducer";
import { ParallaxProvider } from "react-scroll-parallax";
import "../sass/main.scss";
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

const Layout = props => {
  const isClient = typeof window === "object";
  const location = useLocation()

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  const initialThemeState = {
    lang: "en",
    menuOpen: false,
    cfState: "",
    browserWidth: isClient ? window.innerWidth : undefined,
    browserHeight: isClient ? window.innerHeight : undefined
  };
  const [themeOpt, dispatch] = useReducer(ThemeReducer, initialThemeState);

  useEffect(() => {

    //initializeAndTrack(location)

    setTimeout(() => {
      const acceptBtn = document.getElementById('hs-eu-confirmation-button');
      if (acceptBtn) {
        acceptBtn.addEventListener('click', (event) => {
          setCookie('gatsby-gdpr-google-analytics', true, 365)
          setCookie('gatsby-gdpr-google-tagmanager', true, 365)
          initializeAndTrack(location)
          if (window.clarity) {
            window.clarity('consent');
          }
        });
      }

    }, 200);

    const handleResize = () => {
      dispatch({
        type: "UPDATE_BROWSER_DIMENSIONS",
        payload: {
          browserWidth: isClient ? window.innerWidth : undefined,
          browserHeight: isClient ? window.innerHeight : undefined
        }
      });
    };

    const handleScrolling = () => {
      if (window.scrollY > 0) {
        document.body.classList.add("scrolling");
      } else {
        document.body.classList.remove("scrolling");
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScrolling);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScrolling);
    };
  }, [isClient]);

  // useCursorFollow();

  return (
    <ThemeContext.Provider value={{ themeOpt, dispatch }}>
      <ParallaxProvider>
        <>
          <CursorFollow />
          {props.children}
        </>
      </ParallaxProvider>
    </ThemeContext.Provider>
  );
};
export default Layout;
