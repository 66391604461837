const themeReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_BROWSER_DIMENSIONS":
      return {
        ...state,
        browserWidth: action.payload.browserWidth,
        browserHeight: action.payload.browserHeight,
        heroHeight: action.payload.heroHeight
      };
    case "SET_LANG":
      return {
        ...state,
        lang: action.payload.lang
      };
    case "SHOW_OVERLAY":
      return {
        ...state,
        cfState: "close",
        overlayContent: action.payload.overlayContent
      };

    case "HIDE_OVERLAY":
      return {
        ...state,
        cfState: "",
        overlayContent: null
      };

    case "SPLASH_SHOWN":
      return {
        ...state,
        splashShown: true
      };

    case "SET_CF_STATE":
      return {
        ...state,
        cfState: action.payload.cfState,
        cfImage: action.payload.cfImage,
      };

    case "SET_CF_STATE_IN_PAGE":
      const cfState = action.payload.cfStateInPage ? 'hidden' : 'default'
      return {
        ...state,
        cfState: cfState,
        cfStateInPage: action.payload.cfStateInPage,
        cfImageInPage: action.payload.cfImageInPage,
        cfImageInPageHeight: action.payload.height,
      };


    default:
      return state;
  }
};
export default themeReducer;
